import { parseOrdinal } from './parse';

const weekdays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

function getTimezoneOffset(d: Date, timeZone: string) {
  const dateComponents = d.toLocaleString('sv', { timeZone }).split(/[\-\s:]/);
  let values = dateComponents.map(c => parseInt(c, 10));
  const utcDate = Date.UTC(
    values[0],
    values[1] - 1, //months are zero based
    values[2],
    values[3],
    values[4],
    values[5],
  );
  return Math.round(utcDate - d.getTime());
}

export class TzDate implements DateWithTimeZone {
  timeZone: string = '';
  offset: number = 0;
  value: number = 0;

  constructor(
    d: number = Date.now(),
    tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ) {
    this.timeZone = tz;
    this.offset = getTimezoneOffset(new Date(d), tz);
    this.value = d;
  }

  static fromNow(
    tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ) {
    return new TzDate(new Date().getTime(), tz);
  }

  static fromJSDate(
    d: Date,
    tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ) {
    return new TzDate(d.getTime(), tz);
  }

  static fromISO(
    iso: string,
    tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
  ) {
    return new TzDate(new Date(iso).getTime(), tz);
  }

  toISO() {
    return new Date(this.value).toISOString();
  }

  addDays(amount: number) {
    this.value += amount * 1000 * 60 * 60 * 24;
    return this;
  }

  date() {
    const t = new Date(this.value + this.offset);
    const month = t.toLocaleString('default', { month: 'short' });
    const d = t.getDate();
    const ord = parseOrdinal(d);
    return `${month} ${d}${ord}`;
  }

  time() {
    const t = new Date(this.value + this.offset);
    let hours = t.getUTCHours();
    let minutes = t.getUTCMinutes();

    let hrs = '';
    let mins = '';
    let period = 'AM';

    if (hours >= 12) {
      period = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    }
    if (hours === 0) {
      hours += 12;
    }
    hrs = `${hours}`;

    if (minutes > 0 && minutes < 10) {
      mins = `:0${minutes}`;
    } else if (minutes >= 10) {
      mins = `:${minutes}`;
    }

    return `${hrs}${mins}${period}`;
  }

  weekday() {
    const t = new Date(this.value + this.offset);
    return weekdays[t.getUTCDay()];
  }

  hours() {
    const t = new Date(this.value + this.offset);
    return t.getUTCHours();
  }

  setHours(value: number) {
    const diff = value - this.hours();
    this.value += diff * 60 * 60 * 1000;
    return this;
  }

  addHours(amount: number) {
    this.value += amount * 60 * 60 * 1000;
    return this;
  }

  minutes() {
    const t = new Date(this.value + this.offset);
    return t.getUTCMinutes();
  }

  setMinutes(value: number) {
    const diff = value - this.minutes();
    this.value += diff * 60 * 1000;
    return this;
  }
}
