import log from 'logger';
import {
  cancelWords,
  CURRENCY_REGEX,
  daysOfWeek,
  negativeWords,
  positiveWords,
  STRIPE_FIVE_PACK_PRICE,
  STRIPE_ONE_PACK_PRICE,
  STRIPE_TEN_PACK_PRICE,
} from './constants';
import { convertDollarsToCents } from './convert';
import { replaceAll } from './format';

/**
 * Returns the lowercased version of the text provided
 *
 * @param text - The http body object
 */
export function parseIncomingSMSText(
  text: string | undefined,
  lowercase: boolean = true,
): string {
  if (!text) {
    return '';
  }

  return lowercase ? text.toLowerCase() : text;
}

/**
 * Returns the item_id field of the http body
 *
 * @param text - The http body object
 */
export function parseIncomingSMSItemId(
  text: string | number | undefined,
): number {
  if (!text) {
    return 0;
  }

  if (typeof text === 'string' && text.length > 500) {
    log.event('twilio', 'id text was too long', { text });
    return 0;
  }

  if (isNaN(Number(text))) {
    text = text + '';
    const match = text.match(/[1-9][0-9]+/m);
    if (!match || !match.length) {
      return 0;
    }
    return parseInt(match[0], 10);
  }

  return Number(text);
}

/**
 * Returns yes if the text is a positive response, no if negative,
 * or unknown if the result could not be determined.
 *
 * @param text - The text to examine
 */
export function parseIncomingSMSConfirmation(text: string | undefined): string {
  if (text) {
    if (parsePositiveResponse(text)) {
      return 'yes';
    }

    if (parseNegativeResponse(text)) {
      return 'no';
    }
  }

  return 'unknown';
}

/**
 * Returns the offer in cents if one was found, -1 if no offer was found
 *
 * @param text - The text supplied by the user
 */
export function parseIncomingSMSOffer(text: string | undefined): number {
  if (!text) {
    return -1;
  }

  // parse the offer from the incoming request
  let m = `${text}`.match(CURRENCY_REGEX);
  if (!m || !m.length) {
    return -1;
  }

  // get the offer into cents
  let offer = m[0];
  offer = replaceAll(offer, '$', '');
  offer = replaceAll(offer, ',', '');
  return convertDollarsToCents(offer);
}

/**
 * Returns the day of the week indicated by the user provided text.
 *
 * @param text - The text supplied by the user
 * @param availableDays - List of available day names
 */
export function parseSelectedDayOfWeek(
  text: string,
  availableDays: DayOfWeek[],
): string | undefined {
  const possibleResponses = text.toLowerCase().split(' ');

  let selectedDays: PickupAvailability = {
    sunday: 0,
    monday: 0,
    tuesday: 0,
    wednesday: 0,
    thursday: 0,
    friday: 0,
    saturday: 0,
  };

  // check each token from the text input to see how many leading
  // characters match a known day of the week
  for (let i = 0; i < daysOfWeek.length; i++) {
    const dayOfWeek = daysOfWeek[i];
    for (let j = 0; j < possibleResponses.length; j++) {
      const response = possibleResponses[j];
      for (let k = 0; k < response.length && k < 3; k++) {
        let check = response.substring(0, k + 1);
        if (dayOfWeek.indexOf(check) === 0) {
          selectedDays[dayOfWeek as DayOfWeek] =
            selectedDays[dayOfWeek as DayOfWeek] + check.length;
        }
      }
    }
  }

  // then determine which day of the week matched the best
  let selectedDay = 'unknown';
  let selectedDayValue = 0;
  for (let i = 0; i < daysOfWeek.length; i++) {
    const dayOfWeek = daysOfWeek[i];
    if (selectedDays[dayOfWeek as DayOfWeek] > selectedDayValue) {
      selectedDay = dayOfWeek;
      selectedDayValue = selectedDays[dayOfWeek as DayOfWeek];
    }
  }

  // and make sure that it was a day that had been marked as available
  for (let i = 0; i < availableDays.length; i++) {
    let availableDay = availableDays[i];
    if (selectedDay === availableDay) {
      return selectedDay;
    }
  }

  return '';
}

/**
 * Returns the time indicated by the user provided text.
 *
 * @param text - The text supplied by the user
 */
export function parseSelectedTime(text: string): TimeDefinition | undefined {
  const timeMatch = text.match(/\d+(:\d\d)?\s*(a|p)?/i);
  if (timeMatch == null || !timeMatch.length || timeMatch[0] === null) {
    return undefined;
  }

  let time = timeMatch[0];
  let post_meridiem = false;
  let ante_meridiem = false;
  let hours = 0;
  let minutes = 0;
  let parsedTime = 0;

  post_meridiem = time.match(/p/i) !== null;
  ante_meridiem = time.match(/a/i) !== null;
  time = time.replace(/^00/, '24');
  parsedTime = parseInt(time.replace(/\D/g, ''));

  if (parsedTime > 0 && parsedTime < 24) {
    hours = parsedTime;
  } else if (parsedTime >= 100 && parsedTime <= 2359) {
    hours = ~~(parsedTime / 100);
    minutes = parsedTime % 100;
  } else if (parsedTime >= 2400) {
    return undefined;
  }

  if (hours == 12 && ante_meridiem === false) {
    post_meridiem = true;
  }

  if (hours > 0 && hours < 8) {
    post_meridiem = true;
  }

  if (hours > 12) {
    post_meridiem = true;
    hours -= 12;
  }

  if (minutes > 59) {
    minutes = 45;
  }

  minutes = Math.ceil(minutes / 5) * 5;

  return {
    hours,
    minutes,
    pm: post_meridiem,
  };
}

/**
 * Returns true if the text contains a postive response.
 *
 * @param text - The text to examine
 */
function parsePositiveResponse(text: string): boolean {
  let lc = text.trim().toLocaleLowerCase();
  if (lc === 'y' || lc === 'yes') {
    return true;
  }

  for (let i = 0; i < positiveWords.length; i++) {
    if (lc.indexOf(positiveWords[i]) > -1) {
      return true;
    }
  }

  return false;
}

/**
 * Returns true if the text contains a negative response.
 *
 * @param text - The text to examine
 */
function parseNegativeResponse(text: string): boolean {
  let lc = text.trim().toLocaleLowerCase();
  if (lc === 'n' || lc === 'no') {
    return true;
  }

  for (let i = 0; i < negativeWords.length; i++) {
    if (lc.indexOf(negativeWords[i]) > -1) {
      return true;
    }
  }

  return false;
}

/**
 * Returns true if the text contains a cancel response.
 *
 * @param text - The text to examine
 */
export function parseCancelResponse(text: string | undefined): boolean {
  if (!text) {
    return false;
  }

  let lc = text.trim().toLocaleLowerCase();
  if (lc === 'q' || lc === 'quit') {
    return true;
  }

  for (let i = 0; i < cancelWords.length; i++) {
    if (lc.indexOf(cancelWords[i]) > -1) {
      return true;
    }
  }

  return false;
}

export function parseStripeSKU(text: string | undefined): OfferTrackrSKU {
  if (text === STRIPE_ONE_PACK_PRICE) {
    const id = process.env.STRIPE_ONE_PACK_PRICE;
    if (!id) {
      throw new Error(`Missing price id for ${text}`);
    }
    return { id: STRIPE_ONE_PACK_PRICE, priceId: id, credits: 1 };
  }

  if (text === STRIPE_FIVE_PACK_PRICE) {
    const id = process.env.STRIPE_FIVE_PACK_PRICE;
    if (!id) {
      throw new Error(`Missing price id for ${text}`);
    }
    return { id: STRIPE_FIVE_PACK_PRICE, priceId: id, credits: 5 };
  }

  if (text === STRIPE_TEN_PACK_PRICE) {
    const id = process.env.STRIPE_TEN_PACK_PRICE;
    if (!id) {
      throw new Error(`Missing price id for ${text}`);
    }
    return { id: STRIPE_TEN_PACK_PRICE, priceId: id, credits: 10 };
  }

  return { id: 'invalid', priceId: 'invalid', credits: 0 };
}

/**
 * Parses a phone number or partial number in ISBN format (+15555555555)
 * to (555) 555-5555.
 *
 * @param text - The text to examine
 * @param lastFour - Only display the last four digits for security
 */
export function parsePhoneNumber(
  phoneNumberString: string,
  lastFour: boolean = false,
) {
  const cleaned = ('' + phoneNumberString).replace('+1', '').replace(/\D/g, '');
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{1,4})$/);
  if (match) {
    return lastFour
      ? `(xxx) xxx-${match[3]}`
      : `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  match = cleaned.match(/^(\d{3})(\d{1,3})$/);
  if (match) {
    if (lastFour) {
      throw new Error('Last four only supported on complete numbers');
    }

    return `(${match[1]}) ${match[2]}`;
  }

  match = cleaned.match(/^(\d{1,3})$/);
  if (match) {
    if (lastFour) {
      throw new Error('Last four only supported on complete numbers');
    }

    return `(${match[1]}`;
  }

  return '';
}

export function parseOrdinal(d: number): string {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function parseEmail(e: string): string {
  if (e && /^\S+@\S+\.\S+$/.test(e)) {
    return e;
  }

  return '';
}

/**
 * Returns the lowercased version of the text provided
 *
 * @param text - The http body object
 */
export function parseText(text: string | undefined): string {
  if (!text) {
    return '';
  }

  return text;
}
