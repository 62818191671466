import '@styles/globals.css';

import { isDev } from '@lib';
import log from '@logger';
import { AuthProvider } from 'contexts/auth';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Slide, ToastContainer } from 'react-toastify';

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  // disable analytics in the developer environment
  useEffect(() => {
    const disableAnalytics = `ga-disable-${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`;
    (window as any)[disableAnalytics] = isDev;
  }, []);

  // send page transitions to ga
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      log.pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>

      <ToastContainer
        closeButton={false}
        hideProgressBar
        newestOnTop
        autoClose={2500}
        position="bottom-right"
        limit={3}
        transition={Slide}
      />
    </>
  );
};

export default App;
