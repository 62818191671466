import * as Sentry from '@sentry/browser';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    mootrack: (...args: any[]) => void;
  }
}

class DevelopmentLogger {
  userId: string = '';

  user(id?: string, data?: LogData) {
    if (id) {
      this.event('login', id);
    } else {
      this.event('logout', '');
    }
  }

  debug(message: string, data?: LogData) {
    console.debug(
      '%cDEBUG:',
      'color: #007991; font-weight: bold; font-size: 14px',
      message,
      data ? data : '',
    );
  }

  event(category: string, message: string = '', data?: LogData) {
    console.info(
      '%cEVENT:',
      'color: #8a9b68; font-weight: bold; font-size: 14px',
      `[${category}] ${message}`,
      data ? data : '',
    );
  }

  pageview(url: string) {
    console.info(
      '%cPAGEVIEW',
      'color: #3E885B; font-weight: bold; font-size: 14px',
      url,
    );
  }

  interaction(action: string, data?: LogData) {
    console.info(
      '%cINTERACTION:',
      'color: #0A090C; font-weight: bold; font-size: 14px',
      `[${action}]`,
      data ? data : '',
    );
  }

  error(component: string, message: string, data?: LogData) {
    console.debug(
      '%cERROR:',
      'color: #89023e; font-weight: bold; font-size: 14px',
      `[${component}] ${message}`,
      data ? data : '',
    );
  }
}

class ProductionLogger {
  userId: string = '';

  user(id?: string, data?: LogData) {
    if (id) {
      Sentry.setUser({
        id,
        ...data,
      });
      this.userId = id;
    } else {
      Sentry.configureScope(scope => scope.clear());
      this.userId = '';
    }
  }

  debug(message: string, data?: LogData) {
    Sentry.addBreadcrumb({
      category: 'debug',
      message,
      level: 'debug',
      data,
    });
  }

  event(category: string, message: string, data?: LogData) {
    Sentry.addBreadcrumb({
      category,
      message,
      level: 'info',
      data,
    });
  }

  pageview(url: string) {
    if (typeof window !== 'undefined') {
      window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      });
      window.mootrack('trackPageView');
    }
  }

  interaction(action: string, data?: LogData) {
    if (typeof window !== 'undefined') {
      window.gtag('event', action, data);
    }
  }

  error(component: string, message: string, data?: LogData) {
    Sentry.captureException(new Error(message), {
      level: 'error',
      tags: { component },
      extra: { ...data },
    });
  }
}

const logger =
  process.env.NODE_ENV === 'development'
    ? new DevelopmentLogger()
    : new ProductionLogger();

export default logger;
