export const isDev = process.env.NODE_ENV === 'development';

export const daysOfWeek: DayOfWeek[] = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
];

export const timeZones: { value: string; name: string }[] = [
  { value: 'Pacific/Honolulu', name: 'Hawaii' },
  { value: 'America/Anchorage', name: 'Alaska' },
  { value: 'America/Los_Angeles', name: 'Pacific Time' },
  { value: 'America/Phoenix', name: 'Arizona' },
  { value: 'America/Denver', name: 'Mountain Time' },
  { value: 'America/Chicago', name: 'Central Time' },
  { value: 'America/New_York', name: 'Eastern Time' },
];

export const positiveWords = [
  'yes',
  'ya',
  'ye',
  'yep',
  'yup',
  'uh-huh',
  'totally',
  'yeah',
  'totes',
  'sure',
  'you bet',
  'ok',
  'k',
  'okay',
  'okie',
  'alright',
  'right',
  'sounds good',
  'for sure',
  'sure thing',
  'certainly',
  'definitely',
  'of course',
  'gladly',
  'indubitably',
  'absolutely',
  'indeed',
  'undoubtedly',
  'fine',
  'affirmative',
  'very well',
  'obviously',
  'aye',
  'yea',
  'surely',
  'correct',
  'true',
  'roger that',
  'you got it',
  'right on',
  'agreed',
  'that works',
  'very well',
  'naturally',
];

export const negativeWords = [
  'no',
  'nah',
  'nope',
  'nuh-uh',
  'not really',
  'sorry',
  'hardly',
  'negative',
  'nix',
  'never',
  'none',
  'nay',
  'dont',
  "don't",
  'nyet',
  'false',
];

export const cancelWords = [
  'cancel',
  'quit',
  'exit',
  'close',
  'terminate',
  'abandon',
  'leave',
  'withdraw',
  'discontinue',
  'finish',
  'conclude',
  'suspend',
  'fuck',
  'give up',
  'leave',
  'stop',
  'hang up',
  'resign',
  'farewell',
  'goodbye',
  'bow out',
  'taking off',
  'end',
  'halt',
  'cease',
  'abandon',
  'shit',
  'go away',
  'screw',
  'break',
  'shut down',
  'power down',
  'unsubscribe',
  'escape',
];

export const availabilityBlockMap: AvailabilityBlockMap = {
  1: [480, 600],
  2: [600, 720],
  4: [720, 840],
  8: [840, 960],
  16: [960, 1080],
  32: [1080, 1200],
  64: [1200, 1320],
};

export const CURRENCY_REGEX =
  /\$?(\d{1,3},?(\d{3},?)*\d{3}(\.\d{0,2})?|\d{1,3}(\.\d{0,2})?|\.\d{1,2}?)/m;

export const OFFER_ACCEPTED = 'accepted';
export const OFFER_WAITLIST = 'waitlist';
export const OFFER_REJECTED = 'rejected';

export const EVENT_REMINDER = 'reminder';
export const EVENT_WAITLIST = 'waitlist';
export const EVENT_FOLLOWUP = 'followup';

export const STRIPE_ONE_PACK_PRICE = 'STRIPE_ONE_PACK_PRICE';
export const STRIPE_FIVE_PACK_PRICE = 'STRIPE_FIVE_PACK_PRICE';
export const STRIPE_TEN_PACK_PRICE = 'STRIPE_TEN_PACK_PRICE';

export const HOMEPAGE_URL = isDev
  ? 'http://localhost:3000'
  : process.env.NEXT_PUBLIC_WEB_URL || 'https://offertrackr.com';

export const DOCUMENTATION_URL = isDev
  ? 'http://localhost:3001'
  : process.env.NEXT_PUBLIC_WEB_URL || 'https://offertrackr.com';

export const BLOG_URL = isDev
  ? 'http://localhost:3002'
  : process.env.NEXT_PUBLIC_WEB_URL || 'https://offertrackr.com';

export const TWILIO_BUYER_REMINDER = 'buyer_reminder';
export const TWILIO_BUYER_RESCHEDULE = 'buyer_reschedule';
export const TWILIO_COMPLETE_OFFER = 'complete_offer';
export const TWILIO_MAKE_OFFER = 'make_offer';
export const TWILIO_OFFER_ACCEPTED = 'offer_accepted';
export const TWILIO_OFFER_REJECTED = 'offer_rejected';
export const TWILIO_OFFER_WITHDRAWN = 'offer_withdrawn';
export const TWILIO_PICKUP_RESCHEDULED = 'pickup_rescheduled';
export const TWILIO_SALE_CONFIRMATION = 'sale_confirmation';
export const TWILIO_SELLER_REMINDER = 'seller_reminder';
