import { User } from '@supabase/gotrue-js/src/lib/types';
import { supabase, userSignOut } from 'client';
import log from 'logger';
import { createContext, useContext, useEffect, useState } from 'react';

interface Props {
  children: JSX.Element;
}
interface IAuthContext {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  storeSession: (user: User, accessToken: string) => void;
  clearSession: () => void;
}

const AuthContext = createContext<IAuthContext>({
  user: null,
  isAuthenticated: false,
  isLoading: false,
  storeSession: (user: User, accessToken: string) => {},
  clearSession: () => {},
});

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserFromCookies = async () => {
      const { data } = await supabase.auth.getSession();
      if (data.session?.user) {
        storeSession(data.session?.user);
      }
      setLoading(false);
    };

    loadUserFromCookies();
  }, []);

  const storeSession = (user: User) => {
    setUser(user);
    log.user(user.id, { Created: user.created_at });
  };

  const clearSession = () => {
    userSignOut();
    log.user();
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: !!user,
        isLoading: loading,
        storeSession,
        clearSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
