import { convertCentsToDollars } from './convert';
import { TzDate } from './tzDate';

/**
 * Capitalizes the first character of the provided text.
 *
 * @param text - The string to capitalize
 */
export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Returns a time definition object as a human readable string
 *
 * @param t - The time definition object
 */
export function formatTime(t: TimeDefinition): string {
  let hours = t.hours;
  if (hours > 12) {
    hours -= 12;
  } else if (hours === 0 && !t.pm) {
    hours = 12;
  }

  return t.minutes > 0
    ? hours +
        ':' +
        t.minutes.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false,
        }) +
        ' ' +
        (t.pm ? 'pm' : 'am')
    : hours + ' ' + (t.pm ? 'pm' : 'am');
}

/**
 * Returns a set of days as a human readable string
 *
 * @param days - The array of days to format
 */
export function formatAvailabilityDays(days: DayOfWeek[]): string {
  let result: string[] = [...days];
  result = result.map(r => capitalize(r));
  if (result.length > 1) {
    result.splice(result.length - 1, 0, 'or');
  }

  let resultStr = result.join(', ').replace('or,', 'or');

  // remove the comma if there were exactly two days
  if (days.length === 2) {
    resultStr = resultStr.replace(',', '');
  }

  // if today's weekday name is in the list, make sure to indicate
  // that it is next weeks occurrence and not today
  if (days[days.length - 1].toLowerCase() === TzDate.fromNow().weekday()) {
    resultStr =
      days.length > 1
        ? resultStr.replace('or', 'or next')
        : `next ${resultStr}`;
  }

  return resultStr;
}

/**
 * Returns a set of availability blocks as a human readable string
 *
 * @param blocks - The array of blocks to format (in minutes)
 */
export function formatAvailabilityBlocks(blocks: AvailabilityBlock[]): string {
  let results: string[] = [];
  for (let i = 0; i < blocks.length; i++) {
    let start = new Date(2022, 1, 6, 0, blocks[i][0], 0, 0).toLocaleString(
      'en-US',
      { hour: 'numeric', hour12: true },
    );

    let end = new Date(2022, 1, 6, 0, blocks[i][1], 0, 0).toLocaleString(
      'en-US',
      { hour: 'numeric', hour12: true },
    );

    results.push(
      `${start} and ${end}`
        .replace(' AM', ' am')
        .replace(' AM', ' am')
        .replace(' PM', ' pm')
        .replace(' PM', ' pm'),
    );
  }

  if (results.length > 1) {
    results.splice(results.length - 1, 0, 'or');
  }

  return results.join(', ').replace('or,', 'or');
}

/**
 * Returns a set of availability blocks as a human readable string
 *
 * @param blocks - The array of blocks to format
 */
export function formatShortAvailabilityBlocks(
  blocks: AvailabilityBlock[],
  am: string = 'a',
  pm: string = 'p',
): string {
  let results = formatAvailabilityBlocks(blocks);
  return results
    .replace(/\sam/g, am)
    .replace(/\spm/g, pm)
    .replace(/\sand\s/g, ' - ');
}

const fraction = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/**
 * Returns the correct display string in dollars given a value in cents.
 *
 * @param value - The number or string containing a number (1000 or "1000")
 * @returns The display string of the value in dollars
 *
 */
export function formatCentsToDisplay(value: string | number): string {
  const dollars = convertCentsToDollars(value);

  if (dollars % 1 != 0) {
    return fraction.format(dollars);
  }

  return formatter.format(dollars);
}

/**
 * Replaces all instances of the search string with the replacement value.
 *
 * @param text - The text to perform replacements on
 * @param search - The search string to look for
 * @param replacement - The new value to replace the search string with
 */
export function replaceAll(
  text: string,
  search: string,
  replacement: string,
): string {
  return text.replace(new RegExp(search, 'g'), replacement);
}

/**
 * Returns the price values as a property formatted price range.
 *
 * @param price - The price of the item
 * @param minPrice - The minimum price that would be accepted
 */
export function formatPriceRange(price?: number, minPrice?: number) {
  if (minPrice && price && minPrice < price) {
    return `${formatCentsToDisplay(minPrice)} - ${formatCentsToDisplay(price)}`;
  }

  if (price) {
    return formatCentsToDisplay(price);
  }

  return '';
}

/**
 * Joins a list of classnames together.
 *
 * @param classes - Classes to join, falsey classes are ignored
 * @returns - className string
 */
export function classNames(
  ...classes: (string | null | undefined | boolean)[]
) {
  return classes.filter(Boolean).join(' ');
}

/** Logs the last 4 digits of a phone number */
export function logPhone(number: string) {
  if (!number) {
    return '';
  }

  return number.slice(-4);
}
