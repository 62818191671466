/**
 * Converts the time specified in a time defintion to minutes since midnight.
 *
 * @param t - The time definition to convert
 */
export function convertTimeToMinutes(t: TimeDefinition): number {
  if (t.hours === 12 && !t.pm) {
    return t.minutes;
  }

  if (t.hours === 12 && t.pm) {
    return t.hours * 60 + t.minutes;
  }

  return t.hours * 60 + t.minutes + (t.pm ? 720 : 0);
}

/**
 * Converts the time specified in a time to a time definition.
 *
 * @param t - The time definition to convert
 */
export function convertDateTimeToTimeDefinition(
  t: DateWithTimeZone,
): TimeDefinition {
  let hours = t.hours();
  if (hours > 12) {
    hours = hours - 12;
  }

  return {
    hours,
    minutes: t.minutes(),
    pm: t.hours() >= 12,
  };
}

/**
 * Converts the value of a number in dollars to cents.
 *
 * @param value - The number or string containing a number (10.00 or "$10.00")
 */
export function convertDollarsToCents(value: string | number): number {
  value = (value + '').replace(/[^\d.-]/g, '');
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3);
  }

  return value ? Math.round(parseFloat(value) * 100) : 0;
}

/**
 * Converts the value of a number in cents to dollars.
 *
 * @param value - The number or string containing a number (1000 or "1000")
 */
export function convertCentsToDollars(value: string | number): number {
  value = (value + '').replace(/[^\d.-]/g, '');
  value = parseFloat(value) / 100;

  return value || 0;
}

export function convertToSnakeCase(obj: { [key: string]: any }): {
  [key: string]: any;
} {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  let result: { [key: string]: any } = {};
  return Object.entries(obj).reduce((acc, [key, value]) => {
    const snakeKey = key.replace(
      /[A-Z]/g,
      letter => `_${letter.toLowerCase()}`,
    );
    acc[snakeKey] = convertToSnakeCase(value);
    return acc;
  }, result);
}
