import { convertToSnakeCase, logPhone, TzDate } from '@lib';
import log from '@logger';
import { User } from '@supabase/gotrue-js/src/lib/types';
import { createClient } from '@supabase/supabase-js';

// supabase client
export let supabase = createClient(
  process.env.NEXT_PUBLIC_SUPABASE_URL || '',
  process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY || '',
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
    },
  },
);

// base url
export const WEB_URL =
  process.env.NEXT_PUBLIC_WEB_URL || 'https://offertrackr.com';
let lastChannelUpdate = Date.now();

/**
 * Configures a real time subscription to the item and offer database updates
 * @param onItemChange Handler for item changes
 * @param onOfferChange Handler for offer changes
 */
export function createSubscription(
  onItemChange: (itemId: number) => void,
  onOfferChange: (itemId: number, amount: number, status: string) => void,
) {
  const result = supabase
    .channel('changes')
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'items' },
      payload => {
        const ts = new Date(payload.commit_timestamp).getTime();
        if (ts > lastChannelUpdate && 'id' in payload.new) {
          log.event('client.db', 'Item change notification received', {
            itemId: payload.new.id,
          });
          lastChannelUpdate = ts;
          onItemChange(payload.new.id);
        }
      },
    )
    .on(
      'postgres_changes',
      { event: '*', schema: 'public', table: 'offers' },
      payload => {
        const ts = new Date(payload.commit_timestamp).getTime();
        if (
          ts > lastChannelUpdate &&
          'item_id' in payload.new &&
          'amount' in payload.new
        ) {
          log.event('client.db', 'Offer change notification received', {
            itemId: payload.new.item_id,
            amount: payload.new.amount,
            status: payload.new.status,
          });
          lastChannelUpdate = ts;

          onOfferChange(
            payload.new.item_id,
            payload.new.amount,
            payload.new.status,
          );
        }
      },
    )
    .subscribe();

  log.event('client.db', 'Set up realtime change listeners', {
    state: result.state,
  });

  return result;
}

/**
 * Removes all real time subscriptions
 */
export async function removeSubscription() {
  const state = await supabase.removeAllChannels();
  log.event('client.db', 'Removed realtime change listners', {
    state: state[0],
  });
}

/**
 * Requests an OTP code be sent to the provided phone number.
 * @param phone
 * @returns User and session on success, error otherwise.
 */
export async function userSignIn(phone: string): Promise<LoginResult> {
  const { error } = await supabase.auth.signInWithOtp({
    phone,
    options: { shouldCreateUser: true },
  });

  if (error) {
    log.error('client.db', 'Failed to send OTP code to user', {
      message: error.message,
      phone: logPhone(phone),
    });
    return { error };
  }

  log.event('client.db', 'Sent OTP code to user', { phone: logPhone(phone) });
  return {};
}

/**
 * Verifies the OTP code provided by the user and logs them in.
 * @param phone
 * @param token
 * @returns User and session on success, error otherwise.
 */
export async function userVerifyOTP(
  phone: string,
  token: string,
): Promise<LoginResult> {
  const { data, error } = await supabase.auth.verifyOtp({
    phone,
    token,
    type: 'sms',
  });

  if (error) {
    log.event('database', 'Failed to verify OTP code sent to user', {
      message: error.message,
      phone: logPhone(phone),
    });
    return { error };
  }

  if (!data.user) {
    log.error('client.db', 'Failed to retrieve user after signin', {
      phone: logPhone(phone),
    });
    return { error: new Error('Something has gone wrong, please try again.') };
  }

  log.event('client.db', 'Logged user in', { userId: data.user.id });
  return { user: data.user, session: data.session };
}

/**
 * Attempts to sign out the current user.
 * @returns Null on success, error otherwise.
 */
export async function userSignOut(): Promise<boolean> {
  const { error } = await supabase.auth.signOut();

  if (error) {
    log.error('client.db', 'Failed to log user out', { error: error.message });
    return false;
  }

  removeSubscription();
  log.event('client.db', 'Logged out current user');
  return true;
}

/**
 * Gets the current user
 * @returns The currently logged in user
 */
export async function getUser(): Promise<User | undefined> {
  const { data, error } = await supabase.auth.getUser();

  if (error) {
    log.error('client.db', 'Failed to get current user', {
      error: error.message,
    });
    return undefined;
  }

  if (!data.user) {
    log.error('client.db', 'Failed to get current user');
    return undefined;
  }

  log.event('client.db', 'Fetched the current user', { userId: data.user.id });
  return data.user;
}

/**
 * Gets the profile for the currently logged in user.
 * @returns Profile if successful, throws error otherwise.
 */
export async function getProfile(): Promise<UserProfile | undefined> {
  const { data, error } = await supabase.from('profiles').select('*');

  if (error) {
    log.error('client.db', 'Failed to get current user profile', {
      error: error.message,
    });
    return undefined;
  }

  if (!data || !data[0]) {
    log.error('client.db', 'Failed to get current user profile');
    return undefined;
  }

  const profile = data[0];
  log.event('client.db', 'Fetched the current user profile', {
    userId: profile.id,
  });

  return {
    id: profile.id,
    name: profile.name,
    phoneNumber: profile.phone_number,
    address: profile.address,
    city: profile.city,
    state: profile.state,
    timeZone: profile.time_zone,
    offerWindowMin: profile.offer_window_min,
    dropoffDistance: profile.dropoff_distance,
    twilioBuyerNumber: profile.twilio_buyer_number,
    twilioSellerNumber: profile.twilio_seller_number,
    availableCredits: profile.available_credits,
    sundayAvailability: profile.sunday_availability,
    mondayAvailability: profile.monday_availability,
    tuesdayAvailability: profile.tuesday_availability,
    wednesdayAvailability: profile.wednesday_availability,
    thursdayAvailability: profile.thursday_availability,
    fridayAvailability: profile.friday_availability,
    saturdayAvailability: profile.saturday_availability,
    referralId: profile.referral_id,
    createdAt: TzDate.fromISO(profile.created_at, profile.time_zone),
  };
}

/**
 * Updates current user profile with supplied values.
 * @param userId Id of the user to update
 * @param next The new values to apply to the profile
 * @returns Null on success, error otherwise.
 */
export async function updateProfile(
  userId: string,
  next: Partial<UserProfile>,
): Promise<boolean> {
  const { error } = await supabase
    .from('profiles')
    .update(convertToSnakeCase(next))
    .eq('id', userId);

  if (error) {
    log.error('client.db', 'Failed to update user profile', {
      userId,
      error: error.message,
    });
    return false;
  }

  log.event('database', 'Saved user profile', { userId });
  return true;
}

/**
 * Gets all of the items that the currently logged in user has created
 * @returns Items if successful, empty array otherwise
 */
export async function getItems(): Promise<Item[]> {
  const { data, error } = await supabase.from('items').select('*');

  if (error) {
    log.error('client.db', 'Failed to get user items', {
      error: error.message,
    });
    return [];
  }

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  log.event('database', 'Fetched user items', { count: (data || []).length });
  return (data || []).map(item => ({
    id: item.id,
    no: item.no,
    lookupId: item.lookup_id,
    userId: item.user_id,
    status: item.status,
    name: item.name,
    title: item.title,
    description: item.description,
    price: item.price,
    minPrice: item.min_price,
    soldPrice: item.sold_price,
    offerWindowMin: item.offer_window_min,
    exchangeType: item.exchange_type,
    address: item.address,
    city: item.city,
    state: item.state,
    dropoffDistance: item.dropoff_distance,
    buyerInstructions: item.buyer_instructions,
    activity: item.activity,
    twilioBuyerNumber: item.twilio_buyer_number,
    twilioSellerNumber: item.twilio_seller_number,
    createdAt: TzDate.fromISO(item.created_at, tz),
    lastViewedAt: TzDate.fromISO(item.last_viewed_at, tz),
  }));
}

/**
 * Retrieves an item from the database by id. Item must have been created
 * by the currently logged in user.
 * @param id Id of the item to return
 * @param tz Timezone to use when converting dates, uses local timezone by default
 * @returns Item if successul, error otherwise
 */
export async function getItemById(
  id: string | number,
  tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
): Promise<Item | undefined> {
  const { data, error } = await supabase
    .from('items')
    .select('*')
    .eq('id', parseInt(`${id}`, 10));

  if (error) {
    log.error('client.db', 'Failed to get user item', {
      itemId: id,
      error: error.message,
    });
    return undefined;
  }

  if (!data || !data.length) {
    log.error('client.db', 'Failed to get user item', {
      itemId: id,
    });
    return undefined;
  }

  const item = data[0];

  log.event('database', 'Fetched item', { itemId: id });
  return {
    id: item.id,
    no: item.no,
    lookupId: item.lookup_id,
    userId: item.user_id,
    status: item.status,
    name: item.name,
    title: item.title,
    description: item.description,
    price: item.price,
    minPrice: item.min_price,
    soldPrice: item.sold_price,
    offerWindowMin: item.offer_window_min,
    exchangeType: item.exchange_type,
    address: item.address,
    city: item.city,
    state: item.state,
    dropoffDistance: item.dropoff_distance,
    buyerInstructions: item.buyer_instructions,
    activity: item.activity,
    twilioBuyerNumber: item.twilio_buyer_number,
    twilioSellerNumber: item.twilio_seller_number,
    createdAt: TzDate.fromISO(item.created_at, tz),
    lastViewedAt: TzDate.fromISO(item.last_viewed_at, tz),
  };
}

/**
 * Creates a new item.
 * @param next The data to use when creating the item.
 * @param tz Timezone to use when converting dates, uses local timezone by default
 * @returns Item created if successful, error otherwise.
 */
export async function createItem(
  next: Partial<Item>,
  tz: string = Intl.DateTimeFormat().resolvedOptions().timeZone,
): Promise<{ id: string | null; error: any | null }> {
  const { data, error } = await supabase
    .from('items')
    .insert([convertToSnakeCase(next)])
    .select('id');

  if (error) {
    log.error('client.db', 'Failed to create item', {
      error: error.message,
    });
    return { id: null, error };
  }

  if (!data || !data.length) {
    log.error('client.db', 'Failed to return newly created item');
    return { id: null, error };
  }

  const item = data[0];
  log.event('database', 'Created new item', { itemId: item.id });
  return {
    id: item.id,
    error: null,
  };
}

/**
 * Updates an existing item with new values.
 * @param id Id of the item to update.
 * @param next Values to update.
 * @returns True if the item was updated, false otherwise
 */
export async function updateItem(
  id: number,
  next: Partial<Item>,
): Promise<boolean> {
  const { error } = await supabase
    .from('items')
    .update(convertToSnakeCase(next))
    .eq('id', id);

  if (error) {
    log.error('client.db', 'Failed to update item', {
      itemId: id,
      error: error.message,
    });
    return false;
  }

  log.event('database', 'Updated item', { itemId: id });
  return true;
}

/**
 * Updates an item status to move between active and inactive.
 * @param userId Id of the user making the change
 * @param itemId Id of the item to update
 * @param status The new status of the item
 * @param amountPaid The amount paid if the item was sold
 * @returns Null on success, error otherwise.
 */
export async function updateItemStatus(
  userId: string,
  itemId: number,
  status: ItemStatus,
  amountPaid?: number,
): Promise<boolean> {
  const { error } = await supabase.from('item_statuses').insert(
    convertToSnakeCase({
      userId,
      itemId,
      initatedBy: 'seller',
      status,
      amountPaid: amountPaid || 0,
    }),
  );

  if (error) {
    log.error('client.db', 'Failed to update item status', {
      itemId,
      status,
      error: error.message,
    });
    return false;
  }

  log.event('database', 'Updated item status', { itemId, status });
  return true;
}

/**
 * Gets all of the offers that the currently logged in user has received
 * for all of their items.
 * @returns Offers if successful, empty array otherwise.
 */
export async function getOffers(): Promise<ItemOffer[]> {
  let { data, error } = await supabase.rpc('get_offers');

  if (error) {
    log.error('client.db', 'Failed to get offers', {
      error: error.message,
    });
    return [];
  }

  log.event('database', 'Fetched offers for current user', {
    count: (data || []).length,
  });
  return (data || []).map((offer: any) => ({
    id: offer.id,
    itemId: offer.item_id,
    status: offer.status,
    amount: offer.amount,
    buyerName: offer.buyer_name,
    pickupAt: offer.pickup_at && TzDate.fromISO(offer.pickup_at),
    createdAt: TzDate.fromISO(offer.created_at),
  }));
}

/**
 * Gets all of the offers that the currently logged in user has received
 * for a particular item.
 * @returns Offers if successful, empty array otherwise.
 */
export async function getItemOffers(id: number): Promise<ItemOffer[]> {
  let { data, error } = await supabase
    .from('offers')
    .select('*')
    .eq('item_id', id);

  if (error) {
    log.error('client.db', 'Failed to get offers', {
      error: error.message,
    });
    return [];
  }

  log.event('database', 'Fetched offers for current user', {
    count: (data || []).length,
  });
  return (data || []).map(offer => ({
    id: offer.id,
    messageSid: offer.message_sid,
    itemId: offer.item_id,
    status: offer.status,
    amount: offer.amount,
    buyerName: offer.buyer_name,
    pickupAt: offer.pickup_at && TzDate.fromISO(offer.pickup_at),
    createdAt: TzDate.fromISO(offer.created_at),
  }));
}

/**
 * Gets purchase details for the Stripe purchase that just occurred.
 * @returns Purchases if successful, empty array otherwise.
 */
export async function getLatestPurchase(): Promise<TrackrPurchase | undefined> {
  const { data, error } = await supabase
    .from('credits')
    .select('*')
    .eq('source', 'Stripe')
    .order('created_at', { ascending: false })
    .limit(1);

  if (error) {
    log.error('client.db', 'Failed to get purchases', {
      error: error.message,
    });
    return undefined;
  }

  log.event('database', 'Fetched purchases for current user', {
    count: (data || []).length,
  });

  let purchases = (data || []).map(purchase => ({
    transactionId: purchase.transaction_id,
    userId: purchase.user_id,
    amount: purchase.amount,
    price: purchase.price,
    source: purchase.source,
    name: purchase.name,
    email: purchase.email,
    line1: purchase.line1,
    line2: purchase.line2,
    city: purchase.city,
    state: purchase.state,
    postal_code: purchase.postal_code,
    country: purchase.country,
    sku: purchase.sku,
    discount: purchase.discount,
    tax: purchase.tax,
    createdAt: TzDate.fromISO(purchase.created_at),
  }));

  for (let i = 0; i < purchases.length; i++) {
    const p = purchases[i];
    if (p.createdAt.value > Date.now() - 600000000) {
      return p;
    }
  }

  return undefined;
}
